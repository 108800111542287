export const ERROR_TRY_AGAIN_LATER =
  'Ha ocurrido un error, inténtalo más tarde.';
export const ERROR_AUTHENTICATION = 'Error de autenticación.';
export const ERROR_REGISTRATION = 'Error al registrar.';
export const ERROR_MISSING_FIELDS = 'Por favor, completa todos los campos.';
export const ERROR_TERMS_CONDITIONS_UNACCEPTED =
  'Debes aceptar los términos y las condiciones.';
export const ERROR_EMAIL_NOT_VALID = 'El correo electrónico no es válido.';
export const ERROR_PHONE_NOT_VALID = 'El número de teléfono no es válido.';
export const ERROR_PIN_NOT_VALID = 'El PIN no es válido.';
export const ERROR_POSTALCODE_NOT_VALID = 'El código postal no es válido.';
export const ERROR_DNI_NOT_VALID = 'El DNI/NIE no es válido.';
export const ERROR_CREATE_BUDGET = 'Error al crear el presupuesto.';
export const ERROR_CREATE_MEDICAL_CERTIFICATE =
  'Error al crear el justificante médico.';
export const ERROR_FETCHING_DATA = 'Error fetching data from the API.';
export const ERROR_FETCHING_PROFESSIONALS = 'Error fetching professionals: .';
export const ERROR_POST = 'Error al enviar la solicitud POST: .';
export const ERROR_GETTING_DATA = 'Error al obtener los datos:.';
export const ERROR_SEND_MESSAGE = 'Error enviando mensaje:.';
export const ERROR_RESPONSE_MESSAGE = 'Error recibiendo el mensaje:.';
export const ERROR_ACTION_MESSAGE = 'Error acción del mensaje desconocida:.';
export const EMAIL_REQUIRED = 'Email is required.';
export const INVALID_EMAIL_FORMAT = 'Invalid email format.';
export const PHONE_REQUIRED = 'Phone is required.';
export const INVALID_PHONE_FORMAT = 'Invalid phone format (10 digits).';
export const CHECK_IN_INCORRECT = 'Check-in Incorrecto.';
export const ERROR_UPDATE_DERMAQUESTIONS = 'Error updating derma questions.';
export const ERROR_UPDATE_HAIRQUESTIONS = 'Error updating hair questions.';
export const ERROR_GET_DERMAROUTINES = 'Error getting derma routines.';
export const ERROR_GET_DERMADIAGNOSIS = 'Error getting derma diagnosis.';
export const ERROR_UPLOAD_IMAGE = 'Error uploading image.';
export const ERROR_SET_DIAGNOSTIC_COMMENT = 'Error setting diagnostic comment.';
export const ERROR_UPDATE_AESTHETICQUESTIONS =
  'Error updating aesthetic questions.';
export const ERROR_GET_AESTHETICQUESTIONS =
  'Error getting aesthetic questions.';
export const ERROR_SEND_BUDGET_WHATSAPP = 'Error sending budget via whatsapp.';
